// On page load or when changing themes, best to add inline in `head` to avoid FOUC
if (
  localStorage.theme === 'dark' ||
  (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
) {
  document.documentElement.classList.add('tw-dark');
  localStorage.theme = 'dark';
} else {
  document.documentElement.classList.remove('tw-dark');
  localStorage.theme = 'light';
}
