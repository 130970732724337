
import Vue from 'vue';

import FrkVersionUpgrader from '@frk/commons/ui/components/FrkVersionUpgrader.vue';
import UserNotificationsContainer from '@frk/commons/ui/components/UserNotificationsContainer.vue';
import type { MetaInfo } from '@frk/commons/ui/plugins/vue-meta';

import FrkGtmTracking from './components/FrkGtmTracking.vue';
import UserTermsModal from './components/UserTermsModal.vue';

import { buildMetaInfo } from '../helpers';

export default Vue.extend({
  components: {
    FrkGtmTracking,
    FrkVersionUpgrader,
    UserNotificationsContainer,
    UserTermsModal,
  },
  metaInfo(): MetaInfo {
    // The base URL cannot be deducted from window.location in a prerendering context
    const { VUE_APP_BASE_URL } = process.env;
    const { pathname } = window.location;
    const canonicalUrl = `${VUE_APP_BASE_URL}${pathname}`;

    // The default SEO tags can be overriden in each page. See https://vue-meta.nuxtjs.org/guide/metainfo.html
    return buildMetaInfo({
      title: 'Plateforme de freelances informatiques',
      description:
        'La plateforme FreelanceRepublik sélectionne et vous propose les freelances tech les plus qualifiés pour mener à bien vos missions.',
      socialImage: 'https://s3.eu-west-3.amazonaws.com/frk-production/publicFiles/og-image.png?v=1',
      // Tradeoffs: the canonical link is setup on initial page loading but not refreshed on navigation
      link: [{ rel: 'canonical', href: canonicalUrl }],
      meta: [
        { name: 'og:site_name', content: 'FreelanceRepublik', vmid: 'og:site_name' },
        { name: 'og:type', content: 'website', vmid: 'og:type' },
        { name: 'og:url', content: 'https://app.freelancerepublik.com', vmid: 'og:url' },
        { name: 'twitter:card', content: 'summary', vmid: 'twitter:card' },
        { name: 'twitter:site', content: '@Freelance_Talks', vmid: 'twitter:site' },
      ],
      titleTemplate: titleChunk => (titleChunk ? `${titleChunk} - FreelanceRepublik` : 'FreelanceRepublik'),
    });
  },
});
