import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
  User as Auth0User,
  GetTokenSilentlyOptions,
  LogoutOptions,
  RedirectLoginOptions,
  RedirectLoginResult,
} from '@auth0/auth0-spa-js';
import _Vue, { PropType } from 'vue';
import { apiObject } from 'rudder-sdk-js';

import { Role } from '@frk/graphql-types';

import {
  FREELANCE_STATUS_OPTIONS,
  FreelanceTrait,
  JOBS_OPTIONS,
  EXPECTED_CONTRACT_OPTIONS,
} from '../../api/models/Freelance.core';
import { hasRoles } from '../../api/models/Role.core';
import { UserTrait } from '../../api/models/User.core';
import { getLabelByValue } from '../../api/models/utils.core';
import { LoggedUserDocument, LoggedUserQuery } from './auth.generated';

type LoggedUser = LoggedUserQuery['loggedUser'] | null;

export const VueAuth0 = _Vue.extend({
  props: {
    apolloClient: {
      type: Object,
    },
    auth0ClientOptions: {
      type: Object as PropType<Auth0ClientOptions>,
    },
    defaultRedirectUri: {
      type: String,
    },
    logoutReturnTo: {
      type: String,
    },
    onRedirectCallback: {
      type: Function as PropType<(appState: RedirectLoginResult<any>) => void>,
    },
  },
  data(): {
    loading: boolean;
    isAuthenticated: boolean;
    auth0Client: Auth0Client | null;
    auth0User: Auth0User | undefined | null;
    loggedUser: LoggedUser;
    error: unknown | null;
  } {
    return {
      loading: true,
      isAuthenticated: false,
      auth0Client: null,
      auth0User: null,
      loggedUser: null,
      error: null,
    };
  },
  computed: {
    isFreelance(): boolean {
      // TODO Adapt function models to Codegen
      // @ts-ignore
      return hasRoles(this.loggedUser, [Role.Freelance]);
    },
    isClient(): boolean {
      // TODO Adapt function models to Codegen
      // @ts-ignore
      return hasRoles(this.loggedUser, [Role.Client]);
    },
  },
  methods: {
    /** Authenticates the user using the redirect method */
    loginWithRedirect(o: RedirectLoginOptions<any>) {
      return this.auth0Client?.loginWithRedirect({
        redirect_uri: this.defaultRedirectUri,
        ...o,
      });
    },
    /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
    getTokenSilently(o: GetTokenSilentlyOptions) {
      return this.auth0Client?.getTokenSilently(o);
    },
    /** Logs the user out and removes their session on the authorization server */
    logout(o: LogoutOptions = {}) {
      return this.auth0Client?.logout({
        returnTo: this.logoutReturnTo,
        ...o,
      });
    },
    setLoggedUser(user: LoggedUser) {
      this.loggedUser = user;

      if (this.loggedUser) {
        this.$sentry.configureScope(scope => {
          scope.setUser({
            email: this.loggedUser?.email,
            // TODO in fact, _id is not nullable
            // @ts-ignore
            id: this.loggedUser?._id,
          });
        });

        const userProperties = {
          createdAt: this.loggedUser.createdAt,
          email: this.loggedUser.email,
          fullname: this.loggedUser.fullname,
          firstname: this.loggedUser.firstname,
          lastname: this.loggedUser.lastname,
          roles: this.loggedUser.roles,
        } satisfies { [key in UserTrait]: any };

        const properties = this.isFreelance
          ? ({
              ...userProperties,
              expectedContract: getLabelByValue(EXPECTED_CONTRACT_OPTIONS, this.loggedUser.freelance!.expectedContract),
              source: this.loggedUser.freelance!.source,
              jobs: this.loggedUser.freelance!.jobs.map(job => getLabelByValue(JOBS_OPTIONS, job)),
              // We don't query user.client.status in loggedUser and it's not that useful anyway
              status: getLabelByValue(FREELANCE_STATUS_OPTIONS, this.loggedUser.freelance!.status!),
            } satisfies { [key in FreelanceTrait]: any })
          : userProperties;

        this.$rudderanalytics.identify(this.loggedUser._id as string, properties as apiObject);
        this.$rudderanalytics.trackUserLogin();
      }
    },
    async refreshLoggedUser() {
      await this.apolloClient.refetchQueries({
        include: ['loggedUser'],
      });

      // this.setLoggedUser(this.$auth.loggedUser);
    },
  },
  /** Use this lifecycle method to instantiate the SDK client */
  async created() {
    const options = {
      redirect_uri: window.location.origin,
      ...this.auth0ClientOptions,
    };

    // Create a new instance of the SDK client using members of the given options object
    this.auth0Client = await createAuth0Client({
      ...options,
      useRefreshTokens: true,
    });

    try {
      // If the user is returning to the app after authentication..
      if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
        // handle the redirect and retrieve tokens
        const { appState } = await this.auth0Client.handleRedirectCallback();

        this.error = null;

        // Notify subscribers that the redirect callback has happened, passing the appState
        // (useful for retrieving any pre-authentication state)
        this.onRedirectCallback(appState);
      }
    } catch (e) {
      this.error = e;
    } finally {
      // Initialize our internal authentication state
      this.isAuthenticated = await this.auth0Client.isAuthenticated();
      this.auth0User = await this.auth0Client.getUser();

      if (this.isAuthenticated) {
        this.apolloClient
          .watchQuery({
            query: LoggedUserDocument,
          })
          .subscribe(({ data }: { data: { loggedUser: LoggedUser } }) => {
            this.setLoggedUser(data.loggedUser);

            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    }
  },
});

// TODO Ceci est un patch pour l'erreur TS: Type alias 'VueAuth0InstanceType' circularly references itself.
// A fixer quand on aura migré vers Vue 3
export interface VueAuth0InstanceType extends _Vue {
  loginWithRedirect: (o: RedirectLoginOptions<any>) => Promise<void>;
  getTokenSilently: (o: GetTokenSilentlyOptions) => Promise<string | undefined>;
  logout: (o?: LogoutOptions) => Promise<void>;
  isAuthenticated: boolean;
  loading: boolean;
  error: unknown | null;
  auth0User: Auth0User | undefined | null;
  loggedUser: LoggedUser;
  isFreelance: boolean;
  isClient: boolean;
}

let instance: VueAuth0InstanceType;

/** Returns the current instance of the component wrapping the SDK */
export const getInstance = () => instance;

type Options = {
  auth0ClientOptions: Auth0ClientOptions;
  apolloClient: ApolloClient<InMemoryCache>;
  defaultRedirectUri: string;
  logoutReturnTo: string;
  onRedirectCallback: () => void;
};
// Create a simple Vue plugin to expose the wrapper component throughout the application
export default {
  install(Vue: typeof _Vue, options: Options) {
    instance = new VueAuth0({
      propsData: options,
    });

    Vue.prototype.$auth = instance;
  },
};

declare module 'vue/types/vue' {
  interface Vue {
    $auth: VueAuth0InstanceType;
  }
}
