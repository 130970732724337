import { removeDiacritics } from '@frk/helpers';
import { User, Signatory, Role } from '@frk/graphql-types';

import { hasRoles } from './Role.core';

// Mutualize user trait to sync in Rudderstack between frontend and backend
export const UserTraitList = ['createdAt', 'email', 'fullname', 'firstname', 'lastname', 'roles'] as const;
export type UserTrait = (typeof UserTraitList)[number];

// TODO We should not have to check for undefined or null value : this function should just combine firstname and lastname -> the existence of user should be checked before hand -> it should always return a string
// TODO Generate the computed data about a fullname from the backend -> see User.ui functions that have the same pattern
export function getFullname(user: Partial<User> | Signatory | undefined | null): string | null {
  if (!user || !user.firstname || !user.lastname) {
    return null;
  }

  return [user.firstname, user.lastname.toUpperCase()].join(' ');
}

export const DEFAULT_CLIENT_BUSINESS_DEVELOPER_EMAIL = 'vincent@freelancerepublik.com';
// ? Only for testing purposes
export const DEFAULT_TEST_CANDIDATE_TALENT_ADVOCATE_EMAIL = 'clement.avocado@freelancerepublik.com';

// ! Used with a ZUser and a GraphQL User...
export function shouldBeInvited(user: { roles: Role[]; auth0Id?: string; deleted?: boolean }) {
  return (
    (hasRoles(user, [Role.Freelance]) || hasRoles(user, [Role.Client])) &&
    !(typeof user.auth0Id === 'string' && user.auth0Id.length) &&
    user.deleted !== true
  );
}

export function getRefereeMeetingUrl(referee: { firstname: string; lastname: string }) {
  const normalize = (str: string) =>
    removeDiacritics(str)
      .toLowerCase()
      .replaceAll(/[^a-z]/g, '-');

  return `https://rdv.freelancerepublik.com/meetings/${normalize(referee.firstname)}-${normalize(
    referee.lastname
  )}/meet`;
}
