/* eslint-disable */

import * as SchemaTypes from '@frk/graphql-types';

import { gql } from '@frk/graphql-vue-apollo-patch';
import { createMutationFunction, createSmartQueryOptionsFunction, createSmartSubscriptionOptionsFunction } from 'vue-apollo-smart-ops';
import { ApolloError } from '@apollo/client';
export type AcceptTermsMutationVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type AcceptTermsMutation = { __typename?: 'Mutation', acceptTerms?: { __typename?: 'User', _id?: string | null, termsList: Array<any> } | null };


export const AcceptTermsDocument = gql`
    mutation acceptTerms {
  acceptTerms {
    _id
    termsList
  }
}
    `;

/**
 * __acceptTermsMutation__
 *
 * To run a mutation, you call `acceptTermsMutation` within a Vue component and pass it
 * your Vue app instance along with any options that fit your needs.
 *
 * @param app, a reference to your Vue app instance (which must have a `$apollo` property)
 * @param options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.mutate
 * @param client (optional), which can be an instance of `DollarApollo` or the `mutate()` function provided by an `<ApolloMutation>` component
 *
 * @example
 * const { success, data, errors } = acceptTermsMutation(this, {
 *   variables: {},
 * });
 */
export const acceptTermsMutation = createMutationFunction<
  AcceptTermsMutation,
  AcceptTermsMutationVariables,
  ApolloError
>(AcceptTermsDocument);
