import { DEFAULT_STR } from '@frk/helpers';
import Holidays from 'date-holidays';
import humanizeDuration from 'humanize-duration';
import moment from 'moment-business-days';
import { logger } from './utils/logger';

// humanizer closure that receives the units configuration object
const defaultHumanizeDurationConfig = {
  language: 'fr',
  units: ['y', 'mo'],
  round: true,
  conjunction: ' et ',
  serialComma: false,
};

function getHumanizer(config) {
  return humanizeDuration.humanizer({
    ...defaultHumanizeDurationConfig,
    ...config,
  });
}

function getThreeYearsOfFrenchHolidays() {
  const hd = new Holidays('FR');
  const currentYear = new Date().getFullYear();

  const nextThreeYears = Array.from({ length: 3 }, (_, index) => currentYear + index);

  return nextThreeYears.reduce((holidaysList, year) => {
    const holidays = hd.getHolidays(year);
    const publicDayHolidays = holidays
      .filter(holiday => holiday.type === 'public')
      .map(holiday => {
        // holiday.date has the format: YYYY-MM-DD HH:mm:ss
        const [date] = holiday.date.split(' ');

        return date;
      });

    return [...holidaysList, ...publicDayHolidays];
  }, []);
}

moment.locale('fr');

moment.updateLocale('fr', {
  holidays: getThreeYearsOfFrenchHolidays(),
  holidayFormat: 'YYYY-MM-DD',
});

/**
 * @typedef {Date|moment.Moment|number|string} Date
 */

/**
 * Attention : Toute modification de l'interface d'une des fonctions est invisible et provoque potentiellement des
 * bugs runtime sans possibilité des les identifier par ESlint ou le parseur JSDoc de l'IDE.
 *
 * It could be quite difficult to decide how a date should be store.
 *
 * - UC1: For a Freelance experience start date, the granularity is YYYY-MM. Nowadays (2019-07-16), the date is
 * interpreted in local time and stored in DB in UTC.
 * ex: createDate('Sep 2017') => "2017-08-31T22:00:00.000Z" whereas "2017-09-01T00:00:00.000Z" is expected.
 * In this UC, we should use createDateUtc('Sep 2017') => "2017-09-01T00:00:00.000Z"
 */
export default {
  /**
   * @return {moment.Moment}
   * @todo Utilité discutable: peu de caractère gagné vs appel direct à moment() ou bien à Date.now()
   */
  now() {
    return moment();
  },
  /**
   * @param diffDate
   * @param type
   * @return {moment.Duration}
   * @todo Utilité discutable
   */
  /**
   * TODO type should be optional
   */
  getDateDuration(diffDate, type) {
    return moment.duration(diffDate, type);
  },
  isDateSame(date1, date2, type) {
    return moment(date1).isSame(moment(date2), type);
  },
  /**
   * @param date
   * @param {string} format
   * @return {boolean}
   */
  isDate(date, format = undefined) {
    if (date === undefined || date === null) {
      return false;
    }
    try {
      return moment(date, format).isValid();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return false;
    }
  },
  /**
   * @param date1
   * @param date2
   * @param {moment.unitOfTime.Diff} unit
   * @returns {number}
   */
  diffDate(date1, date2, unit = null) {
    return this.createDate(date1).diff(this.createDate(date2), unit);
  },
  /**
   * @param {Date} date
   * @param {boolean} [utc=false] Set to true to not convert the date from local time to UTC. Useful when the date
   * to create is 'jan 2019' and we want '2019-01-01T00:00:00.000Z' and not "2018-12-31T22:00:00.000Z".
   * @param {string} format
   * @return {moment.Moment|null}
   */
  createDate(date, utc = false, format = undefined) {
    if (!this.isDate(date, format)) {
      logger.error(`Invalid param data '${date}' - Cannot be converted to date`);
      return null;
    }
    return utc ? moment.utc(date, format) : moment(date, format);
  },
  /**
   * @param {Date} date
   * @param {string} format
   * @returns {string}
   */
  formatDate(date, format, fallback = true) {
    if (this.isDate(date)) {
      return moment(date).format(format);
    }

    if (fallback) {
      return DEFAULT_STR;
    }

    return null;
  },
  humanizeDuration(duration, config = {}) {
    const humanizer = getHumanizer(config);
    return humanizer(duration);
  },
  /**
   * Returns the timestamp of a date in milliseconds.
   *
   * @param {Date} date
   * @return {Number|null}
   */
  getTimestamp(date) {
    return this.isDate(date) ? moment.utc(date).valueOf() : null;
  },
  /**
   * Returns the timestamp of a date in seconds in local time.
   *
   * @param {Date} date
   * @return {number|null}
   */
  getTimestampSecond(date) {
    return this.isDate(date) ? moment(date).unix() : null;
  },
  isDateBetween(date, dateBegin, dateEnd, param, inclusivity) {
    return moment(date).isBetween(moment(dateBegin), moment(dateEnd), param, inclusivity);
  },
  isDateAfter(date, otherDate) {
    return moment(date).isAfter(otherDate);
  },
  isDateBefore(date, otherDate) {
    return moment(date).isBefore(otherDate);
  },
  isBusinessDay(date) {
    return moment(date).isBusinessDay();
  },
  prevBusinessDay(date) {
    return moment(date).prevBusinessDay();
  },
  nextBusinessDay(date) {
    return moment(date).nextBusinessDay();
  },
  businessDiff(date, otherDate) {
    return moment(date).businessDiff(otherDate);
  },
  /**
   * @param {Date} date
   * @param {moment.unitOfTime.StartOf} unit
   * @return {moment.Moment}
   */
  dateStartOf(date, unit, utc = false) {
    return utc ? moment.utc(date).startOf(unit) : moment(date).startOf(unit);
  },
  /**
   * @param {Date} date
   * @param {moment.unitOfTime.StartOf} unit
   * @param {boolean} [utc=false]
   * @return {moment.Moment}
   */
  dateEndOf(date, unit, utc = false) {
    return utc ? moment.utc(date).endOf(unit) : moment(date).endOf(unit);
  },
  dateAdd(date, nb, unit) {
    if (unit === 'businessDays') {
      return moment(date).businessAdd(nb);
    }

    return moment(date).add(nb, unit);
  },
  /**
   * @param {Date} date
   * @param {Number} nb
   * @param {moment.unitOfTime.DurationConstructor} unit
   * @return {moment.Moment}
   */
  dateSubtract(date, nb, unit) {
    if (unit === 'businessDays') {
      return moment(date).businessSubtract(nb);
    }

    return moment(date).subtract(nb, unit);
  },
};
