// TODO Type the first use store correctly (see borylTrackingPlan for example)
// type FirstUseDataStore = { [key: string]: any };

/* eslint-disable no-param-reassign */
const getInitialState = () => ({
  prospectToken: null,
});

export default {
  namespaced: true,
  state: {
    ...getInitialState(),
  },
  actions: {
    setProspectToken({ commit }, prospectToken) {
      commit('setProspectToken', prospectToken);
    },
  },
  mutations: {
    setProspectToken(state, prospectToken) {
      state.prospectToken = prospectToken;
    },
  },
};
