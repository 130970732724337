import { MarketSituation, PeriodEnum } from '@frk/graphql-types';

import date from '../date';
import { AlgoliaMarketSituation } from '../utils/algolia/AlgoliaIndexer.types';
import { ConstantOption, getLabelByValue } from './utils.core';

export const FREELANCE_AVAILABILITY_DURATION_DAYS = 14;
export const FREELANCE_SOON_AVAILABLE_DURATION_DAYS = 60;

export const PERIOD_OPTIONS: ConstantOption<PeriodEnum>[] = [
  { value: PeriodEnum.P0W, label: 'Aucun préavis', default: true },
  { value: PeriodEnum.P1W, label: '1 semaine' },
  { value: PeriodEnum.P2W, label: '2 semaines' },
  { value: PeriodEnum.P3W, label: '3 semaines' },
  { value: PeriodEnum.P1M, label: '1 mois' },
  { value: PeriodEnum.P2M, label: '2 mois' },
  { value: PeriodEnum.P3M, label: '3 mois' },
];

// TODO Get this from GraphQL, right ?
type DateOrString = Date | string;
type AvailableAt = DateOrString | null;

export const isAvailableAtAsPeriod = (availableAt: AvailableAt): availableAt is PeriodEnum =>
  PERIOD_OPTIONS.some(option => option.value === availableAt);

// TODO Take decision : custom date functions or dateUtils
export const isAvailableAtAsDate = (availableAt: AvailableAt): availableAt is DateOrString => {
  if (!availableAt) {
    return false;
  }
  const d = new Date(availableAt);
  return !Number.isNaN(d.getTime());
};

export function getAvailabilityDate(marketSituation: MarketSituation) {
  if (!marketSituation.availableAt) {
    return null;
  }

  // TODO Why is available date the updated date if the availability is a period ?
  if (isAvailableAtAsPeriod(marketSituation.availableAt)) {
    return date.createDate(marketSituation.updatedAt)!.toDate();
  }

  if (isAvailableAtAsDate(marketSituation.availableAt)) {
    return marketSituation.availableAt ? date.createDate(marketSituation.availableAt)!.toDate() : null;
  }

  return null;
}

export function getFreelanceRemainingDays(marketSituation: MarketSituation) {
  const availabilityDate = getAvailabilityDate(marketSituation);

  return availabilityDate
    ? date.diffDate(date.dateStartOf(availabilityDate, 'day'), date.dateStartOf(new Date(), 'day'), 'days') +
        FREELANCE_AVAILABILITY_DURATION_DAYS
    : -1;
}

export function hasRecentUpdate(marketSituation: MarketSituation | AlgoliaMarketSituation) {
  return date.isDateAfter(marketSituation.updatedAt, date.dateSubtract(new Date(), 15, 'days'));
}

export function shouldNotBeNotified(marketSituation?: MarketSituation | null) {
  // ? shouldNotBeNotified = freelance is not Listening, not available within 30 days and has updated availability in the last 60 days
  // ? If marketSituation is not defined, if the freelance is listening or the freelance has defined a period, we should notify
  if (!marketSituation || marketSituation.isListening || isAvailableAtAsPeriod(marketSituation.availableAt)) {
    return false;
  }

  const today = new Date();
  const sixtyDaysAgo = date.dateSubtract(today, 60, 'days').toDate();
  if (date.isDateBefore(marketSituation.updatedAt, sixtyDaysAgo)) {
    return false;
  }

  const thirtyDaysFromNow = date.dateAdd(today, 30, 'days').toDate();
  return !marketSituation.availableAt || date.isDateAfter(marketSituation.availableAt, thirtyDaysFromNow);
}

export function formatAvailableAtForCustomerIo(availableAt: AvailableAt) {
  if (isAvailableAtAsPeriod(availableAt)) {
    const label = getLabelByValue(PERIOD_OPTIONS, availableAt);

    return `Disponible avec un préavis de ${label}`;
  }

  if (isAvailableAtAsDate(availableAt)) {
    if (date.isDateBefore(availableAt, new Date())) {
      return 'Disponible immédiatement';
    }

    return `Disponible le ${date.formatDate(availableAt, 'DD/MM/YYYY')}`;
  }

  return 'Disponibilité non renseignée';
}
