/**
 * As title, description and socialImage are used on several meta elements, this function
 * helps the developper to build a structure to feed the SEO package 'vue-meta'.
 */
export function buildMetaInfo({ title, description, socialImage, ...metaInfo }) {
  const titleMeta = [
    { name: 'og:title', content: title, vmid: 'og:title' },
    { name: 'twitter:title', content: `FreelanceRepublik - ${title}`, vmid: 'twitter:title' },
  ];
  const descriptionMeta = [
    { name: 'description', content: description, vmid: 'description' },
    { name: 'og:description', content: description, vmid: 'og:description' },
    { name: 'twitter:description', content: description, vmid: 'twitter:description' },
  ];
  const socialImageMeta = [
    { name: 'og:image', content: socialImage, vmid: 'og:image' },
    { name: 'twitter:image', content: socialImage, vmid: 'twitter:image' },
  ];

  return {
    ...metaInfo,
    title,
    meta: [
      ...(title ? titleMeta : []),
      ...(description ? descriptionMeta : []),
      ...(socialImage ? socialImageMeta : []),
      ...(metaInfo.meta || []),
    ],
  };
}
