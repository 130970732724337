
import Vue from 'vue';

import {
  GTM_LEGACY_VAR_FREELANCE_JOB,
  GTM_LEGACY_VAR_FREELANCE_PRICE,
  GTM_LEGACY_VAR_VISITOR_ID,
  GTM_LEGACY_VAR_VISITOR_LOCATION,
  GTM_LEGACY_VAR_VISITOR_LOGGING_STATE,
  GTM_LEGACY_VAR_VISITOR_LOGGING_STATE_LOGGED,
  GTM_LEGACY_VAR_VISITOR_LOGGING_STATE_UNLOGGED,
  GTM_LEGACY_VAR_VISITOR_TYPE,
  GTM_LEGACY_VAR_VISITOR_TYPE_CLIENT,
  GTM_LEGACY_VAR_VISITOR_TYPE_FREELANCE,
} from '../plugins/gtm/legacyVars';

export default Vue.extend({
  name: 'FrkGtmTracking',
  mounted() {
    this.$auth.$watch('loggedUser', loggedUser => {
      if (loggedUser) {
        if (this.$auth.isFreelance) {
          Vue.$gtm.trackUserVar(GTM_LEGACY_VAR_VISITOR_TYPE, GTM_LEGACY_VAR_VISITOR_TYPE_FREELANCE);
          Vue.$gtm.trackUserVar(
            GTM_LEGACY_VAR_VISITOR_LOCATION,
            loggedUser.freelance?.geographicAvailability?.address?.city
          );
          Vue.$gtm.trackFreelanceVar(GTM_LEGACY_VAR_FREELANCE_PRICE, loggedUser.freelance?.price?.target);
          Vue.$gtm.trackFreelanceVar(GTM_LEGACY_VAR_FREELANCE_JOB, loggedUser.title);
        }

        if (this.$auth.isClient) {
          Vue.$gtm.trackUserVar(GTM_LEGACY_VAR_VISITOR_TYPE, GTM_LEGACY_VAR_VISITOR_TYPE_CLIENT);
          Vue.$gtm.trackUserVar(GTM_LEGACY_VAR_VISITOR_LOCATION, loggedUser.company?.address?.city);
        }

        Vue.$gtm.trackUserVar(GTM_LEGACY_VAR_VISITOR_ID, loggedUser._id);
        Vue.$gtm.trackUserVar(GTM_LEGACY_VAR_VISITOR_LOGGING_STATE, GTM_LEGACY_VAR_VISITOR_LOGGING_STATE_LOGGED);

        Vue.$gtm.isSynced();
      } else {
        Vue.$gtm.trackUserVar(GTM_LEGACY_VAR_VISITOR_LOGGING_STATE, GTM_LEGACY_VAR_VISITOR_LOGGING_STATE_UNLOGGED);
      }
    });
  },
  render() {
    return null;
  },
});
