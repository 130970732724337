/* eslint-disable no-console */
import StackTrace from 'stacktrace-js';

export const levels = ['error', 'info', 'debug'] as const;
type Level = (typeof levels)[number];

let currentLevelIndex = levels.length - 1;

/**
 * Display a message in the JS console (with the stacktrace), excluding production or staging environment.
 */
function log({ message, level }: { message: any[]; level: Level }) {
  const trace = StackTrace.getSync();
  const functionName = (trace[3] && trace[3].getFunctionName()) || '<anonymous>'; // FIX https://sentry.io/organizations/odhcom/issues/2095389569

  const configEnv = process.env.CONFIG_ENV as string;

  if (levels.indexOf(level) <= currentLevelIndex && !['production', 'staging'].includes(configEnv)) {
    console[level](`[${functionName}]`, ...message);
  }
}

function setLevel(level: Level) {
  const newLevelIndex = levels.indexOf(level);

  if (newLevelIndex === -1) {
    throw new Error(`Invalid param value [level='${level}']`);
  }

  currentLevelIndex = newLevelIndex;
}

type LoggerType = {
  [L in (typeof levels)[number]]: (...message: any[]) => void;
} & { setLevel: (level: Level) => void };

export const logger = levels.reduce(
  (methods, level) => ({
    ...methods,
    [level](...message: any[]) {
      log({ message, level });
    },
  }),
  { setLevel }
) as LoggerType;
