// TODO GTM Legacy: is this thing useful for the business ?? can we delete this ?
/**
 * The user ObjectId when logged or undefined.
 * @type {string}
 */
export const GTM_LEGACY_VAR_VISITOR_ID = 'visitorId';

/**
 * 'freelance' | 'company'. The user role.
 * @type {string}
 */
export const GTM_LEGACY_VAR_VISITOR_TYPE = 'visitorType';
export const GTM_LEGACY_VAR_VISITOR_TYPE_FREELANCE = 'freelance';
export const GTM_LEGACY_VAR_VISITOR_TYPE_CLIENT = 'company';

/**
 * Is the user logged?
 * @type {string}
 */
export const GTM_LEGACY_VAR_VISITOR_LOGGING_STATE = 'visitorLoggingState';
export const GTM_LEGACY_VAR_VISITOR_LOGGING_STATE_LOGGED = 'logged';
export const GTM_LEGACY_VAR_VISITOR_LOGGING_STATE_UNLOGGED = 'unlogged';

/**
 * The city of the User.
 * @type {string}
 */
export const GTM_LEGACY_VAR_VISITOR_LOCATION = 'visitorLocation';

/**
 * The User profile completion rate (Number between 0 and 100).
 * @type {string}
 */
export const GTM_LEGACY_VAR_VISITOR_PROFILE_COMPLETION_RATE = 'visitorProfileCompletionRate';

/**
 * Freelance price.
 * @type {string}
 */
export const GTM_LEGACY_VAR_FREELANCE_PRICE = 'freelancePrice';

/**
 * Freelance title.
 * @type {string}
 */
export const GTM_LEGACY_VAR_FREELANCE_JOB = 'freelanceJob';

/**
 * 'companyRepeater' | 'companyFirstTime' : Has the Client submitted several missions?
 * @type {string}
 */
export const GTM_LEGACY_VAR_COMPANY_STATUS = 'companyStatus';

/**
 * Number of missions submitted by the Client.
 * @type {string}
 */
export const GTM_LEGACY_VAR_COMPANY_TOTAL_MISSION = 'companyTotalMission';
