import { SelectionKeySkillsLevel } from '@frk/graphql-types';
import { ConstantOption } from './utils.core';

export const SKILL_ALLOWED_CHARACTERS_REGEX = /([^A-Za-z0-9À-ÿ.\-#+ ']|( \+ ?)|( \()|(-\())|( - ?)|-$/;
export const SKILL_MAX_LENGTH = 30;
export const SKILL_MIN_LENGTH = 1;

export function justNumber(value: string): boolean {
  return /^\d+$/.test(value);
}

// Althought this constant is based on the same-named CustomerIO constant, both constants should remain independent:
// - This local constant has a business purpose: TAC and Freelance should not write a book description
// - The CustomerIO constant (same name) has a technical purpose: don't reach the attribute size limit (1000 bytes).
export const FREELANCE_KEYSKILL_DESCRIPTION_MAX_SIZE = 500;

export const SELECTION_KEY_SKILLS_LEVEL_OPTIONS: ConstantOption<SelectionKeySkillsLevel>[] = [
  { value: SelectionKeySkillsLevel.Level_0, label: 'Aucune experience' },
  { value: SelectionKeySkillsLevel.Level_1, label: 'Débutant' },
  { value: SelectionKeySkillsLevel.Level_2, label: 'Confirmé' },
  { value: SelectionKeySkillsLevel.Level_3, label: 'Senior' },
];
