// TODO GTM Legacy: is this thing useful for the business ?? can we delete this ?
export default {
  clickConnectFromHeader: {
    category: 'header',
    action: 'Se connecter',
  },
  clickRegisterFromHeader: {
    category: 'header',
    action: "S'inscrire",
  },
  inscriptionFromAuh0: {
    category: 'pop-in connexion / inscription',
    action: 'connexion',
    label: 'mail',
  },
  connectionFromAuh0: loginType => ({
    category: 'pop-in connexion / inscription',
    action: 'connexion',
    label: loginType,
  }),
  clickSearchFreelanceFirstUseStep1: {
    category: 'tunnel inscription entreprise',
    action: 'step 1 - choix besoin',
    label: 'Recherche freelance',
  },
  clickClientFirstUseStep2: {
    category: 'tunnel inscription entreprise',
    action: 'step 2 - Description',
    label: 'Continuer',
  },
  clickClientFirstUseStep3: {
    category: 'tunnel inscription entreprise',
    action: 'step 3 - Disponibilité',
    label: 'Continuer',
  },
  clickClientFirstUseStep4: {
    category: 'tunnel inscription entreprise',
    action: 'step 4 - Informations',
    label: 'Terminer',
  },
  clickClientFirstUseStep5: {
    category: 'tunnel inscription entreprise',
    action: 'step 5 - Final',
    label: 'Enregistrer et continuer',
  },
  clickSearchMissionFirstUseStep1: {
    category: 'tunnel inscription freelance',
    action: 'step 1 - choix besoin',
    label: 'Recherche mission',
  },
  clickFreeFirstUseStep2: {
    category: 'tunnel inscription freelance',
    action: 'step 2 - infos identite',
    label: 'Etape suivante',
  },
  clickFreeFirstUseStep3: {
    category: 'tunnel inscription freelance',
    action: 'step 3 - competences',
    label: 'Etape suivante',
  },
  clickFreeFirstUseStep4: {
    category: 'tunnel inscription freelance',
    action: 'step 4 - infos freelance',
    label: 'Enregistrer et accéder au dashboard',
  },
  freelanceParticipation: {
    category: 'dashboard',
    action: 'Rejoindre round',
  },
  clickAddMission: {
    category: 'dashboard',
    action: 'ajouter mission',
    label: 'ouvrir pop-in',
  },
  addMission: profile => ({
    category: 'dashboard',
    action: 'ajouter mission',
    label: `mission ajoutée - ${profile}`,
  }),
  deleteMission: {
    category: 'dashboard',
    action: 'ouvrir mission',
    label: 'supprimer mission',
  },
  recruit: {
    category: 'dashboard',
    action: 'ouvrir mission',
    label: 'activer mission',
  },
  finishMission: {
    category: 'dashboard',
    action: 'ouvrir mission',
    label: 'terminer mission',
  },
  deleteFreeAccount: {
    category: 'account',
    action: 'Suppression compte',
    label: 'suppression compte freelance',
  },
  deleteClientAccount: {
    category: 'account',
    action: 'Suppression compte',
    label: 'suppression compte entreprise',
  },
  clickFooterBlog: {
    category: 'footer',
    action: 'Consulter blog',
  },
  clickFooterMail: {
    category: 'footer',
    action: 'Contact mail',
  },
  clickFooterTel: {
    category: 'footer',
    action: 'Contact tel',
  },
  clickFooterSocial: socialNetwork => ({
    category: 'footer',
    action: 'Consulter réseaux sociaux',
    label: socialNetwork,
  }),
  clickSearchFreeFromHome: {
    category: 'homepage',
    action: 'je cherche un freelance',
  },
  clickSearchMissionFromHome: {
    category: 'homepage',
    action: 'je cherche une mission',
  },
};
